import './App.css';
import ZlatanComponent from './hellomessage';
import { useState } from 'react';
import './contactform.js';
import React from 'react';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

 

  return (
    <>
      <section className='uvodna'>
        <header>
          <div className="navbar">
            <div className="logo"><a href="#">ZlatanDev</a></div>
            <ul className={`links ${menuOpen ? 'active' : ''}`}>
              <li><a href="Services">Services</a></li>
              <li><a href="Projects">Projects</a></li>
              <li><a href="About">About</a></li>
            </ul>
            <a href="#" className="action_btn">Get started</a>
            <div className="toggle_btn" onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </header>

        <div className="name">
          <h2>Hello,</h2>
          <p>I'm Zlatan</p>
        </div>
        <div className='avatar'>
          <img src="avatarnovi.png" alt="Zlatan" />
        </div>
        <main>
          <ZlatanComponent />
        </main>

        <div className='hometext'>
          <h3>I'm Zlatan, a versatile and dynamic professional with a passion for creating<br />innovative digital solutions. With a diverse skill set that spans full stack development,<br />meta ads specialization, content creation, and UX design,<br />I bring a holistic approach to every project I undertake.</h3>
        </div>
      </section>

      <section className='projects'>
        <div className='uvodnitekst'>
          <h1>Recent projects</h1>
          <p>Focused on creating new world of possibilities</p>
        </div>

        <div className='polja'>
          <div className='project-card'>
            <div className='project-image'>
              <img src="slikica2.png" alt="Project 1" />
            </div>
            <div className='project-text'>
              <h1>Access Solutions</h1>
              <p>A responsive, feature-rich portfolio website for a cybersecurity company.</p>
              <a href="https://access.ba/" className="button">Visit website</a>
            </div>
          </div>

          <div className='project-card'>
            <div className='project-image'>
              <img src="ielts.png" alt="Project 2" />
            </div>
            <div className='project-text'>
              <h1>IELTS Kate</h1>
              <p>Managing Facebook ads account, and delivering new international students<br />every month.</p>
              <a href="https://www.ielts-kate.com/" className="button">Visit website and Facebook page</a>
            </div>
          </div>

          <div className='project-card'>
            <div className='project-image'>
              <img src="pop.png" alt="Project 3" />
            </div>
            <div className='project-text'>
              <h1>Pop Rock School Sarajevo</h1>
              <p>Managing social media accounts, creating engaging creatives, content creator.</p>
              <a href="https://www.ielts-kate.com/" className="button">Visit website, Instagram and Facebook page</a>
            </div>
          </div>

          <div className='project-card'>
            <div className='project-image'>
              <img src="maths.png" alt="Project 4" />
            </div>
            <div className='project-text'>
              <h1>Mathszx Tutoring School London</h1>
              <p>Interactive website, creating engaging creatives, delivering new students<br />via Facebook ads.</p>
              <a href="https://www.mathszxtutoringservices.co.uk/" className="button">Visit website, Instagram and Facebook page</a>
            </div>
          </div>

          <div className='project-card'>
            <div className='project-image'>
              <img src="osfg.png" alt="Project 5" />
            </div>
            <div className='project-text'>
              <h1>Fatima Gunic High School</h1>
              <p>Creating beautiful creatives, managing portfolio website.</p>
              <a href="https://osfg.edu.ba/" className="button">Visit website</a>
            </div>
          </div>

          <div className='project-card'>
            <div className='project-image'>
              <img src="project6.jpg" alt="Project 6" />
            </div>
            <div className='project-text'>
              <h1>Project 6</h1>
              <p>Description of Project 6...</p>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className='oko'>
          <h1>About me</h1>
          <p>Experienced professional, with plenty years of experience in Fullstack development,<br /> social media marketing, content creation.</p>
        </div>
        <div className='project-slika'>
          <img src="zh.png" alt="Project 1" />
        </div>
        <div className='project-tekst'>
          <h1>Skills that drive innovation and success.</h1>
        </div>
        
        <div className='opis'>
          <div className='opistekst'>
            <h1>About me</h1>
          </div>
          <div className='image'>
            <img src="avatarnovi.png" alt="Avatar" />
          </div>
          <div className='detalji'>
            <p>I am a dedicated and passionate Fullstack developer and social<br /> media marketer with a strong focus on creating impactful digital<br /> experiences. With years of experience in both Fullstack development<br /> and social media marketing, I have honed my skills to<br /> deliver responsive, user-friendly websites and application and effective<br /> marketing strategies.</p>
            <a href="" className="button8">Download CV</a>
          </div>

          <div className='skills'>
            <h3 className='info__title'>Skills</h3>
            <div className='skills__content'>
              <div className='skills__box'>
                <img src="js.svg" className='skills__img' alt="Javascript" />
                <span className='skills__name'>Javascript</span>
              </div>
              <div className='skills__box'>
                <img src="nodii.svg" className='skills__img' alt="Node.js" />
                <span className='skills__name'>Node.js</span>
              </div>
              <div className='skills__box'>
                <img src="re.svg" className='skills__img' alt="React.js" />
                <span className='skills__name'>React.js</span>
              </div>
              <div className='skills__box'>
                <img src="ts.svg" className='skills__img' alt="Typescript" />
                <span className='skills__name'>Typescript</span>
              </div>
              <div className='skills__box'>
                <img src="my.svg" className='skills__img' alt="MySql" />
                <span className='skills__name'>MySql</span>
              </div>
              <div className='skills__box'>
                <img src="mango.svg" className='skills__img' alt="MongoDB" />
                <span className='skills__name'>MongoDB</span>
              </div>
            </div>
          </div>
        </div>
      </section>


      
    </>
  );
}


export default App;
